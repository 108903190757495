import { useEffect } from 'react'

declare global {
  interface IFastSpringItem {
    path?: string
    price?: string
    priceTotal?: string
    unitPriceValue?: number
    priceTotalValue?: number
    totalValue?: number
    discountTotalValue?: number
    subscription?: {
      nextChargeTotal?: string
      nextChargeTotalValue?: number
      unitPriceValue?: number
      instructions?: {
        intervalLength?: number
        intervalUnit?: string
        discountDurationLength?: number
        priceTotalValue?: number
        totalValue?: number
        type?: string
        discountPercentValue?: number
        discountDurationUnit?: string
      }[]
    }
    future?: {
      priceTotalValue?: number
    }
  }
  interface IFastSpringPayload {
    currency?: string
    country?: string
    groups?: {
      items?: IFastSpringItem[]
    }[]
  }

  interface Window {
    FTFDataCallback: (data?: IFastSpringPayload) => void
    FTFPopupClosed: (
      data: {
        id: string
        reference: string
      } | null
    ) => void
    FTFPopupEventReceived: (data: Record<string, unknown>) => void
    fastspring?: {
      builder: {
        push: (session: {
          products: { path: string }[]
          paymentContact?: {
            email: string | null
          }
          tags?: {
            uid: string
          }
          reset: true
          checkout: true
        }) => void
        remove: (key?: string) => void
        reset: () => void
        country: (country: string) => void
        language: (language: string) => void
      }
    }
  }
}

export function useFastspring(options: {
  onData: typeof window.FTFDataCallback
  onFinish: typeof window.FTFPopupClosed
  onEvent: typeof window.FTFPopupEventReceived
}) {
  useEffect(() => {
    let script =
      document.querySelector<HTMLScriptElement>(`script[id="fsc-api"]`)

    if (!script) {
      script = document.createElement('script')
      script.id = 'fsc-api'
      script.src =
        'https://sbl.onfastspring.com/sbl/0.9.2/fastspring-builder.min.js'
      script.async = true
      script.setAttribute(
        'data-storefront',
        process.env.NEXT_PUBLIC_FASTSPRING_STOREFRONT as string
      )
      script.setAttribute('data-data-callback', 'FTFDataCallback')
      script.setAttribute('data-popup-event-received', 'FTFPopupEventReceived')
      script.setAttribute('data-popup-closed', 'FTFPopupClosed')
      document.body.appendChild(script)
    }

    return () => {
      document.getElementById(`fscCanvas`)?.remove()
      document
        .querySelectorAll<HTMLScriptElement>(`script[id="fsc-api"]`)
        .forEach((item) => item.remove())
    }
  }, [])

  useEffect(() => {
    window.FTFDataCallback = options.onData
    window.FTFPopupClosed = options.onFinish
    window.FTFPopupEventReceived = options.onEvent
  }, [options])

  if (!process.browser) {
    return null
  }

  return window.fastspring
}
