import { type Theme } from '@emotion/react'

enum ThemeName {
  ILLUSTRATED2 = 'illustrated2',
  ILLUSTRATED3 = 'illustrated3',
  ILLUSTRATED4 = 'illustrated4',
}

export const createTheme = (onboardingVariant: string): Theme => {
  if (onboardingVariant === ThemeName.ILLUSTRATED2) {
    return {
      digitalV2: true,
    }
  } else if (onboardingVariant === ThemeName.ILLUSTRATED3) {
    return {
      digitalV3: true,
    }
  } else if (onboardingVariant === ThemeName.ILLUSTRATED4) {
    return {
      digitalV4: true,
    }
  } else {
    return {}
  }
}

export const getThemeName = (theme: Theme) => {
  if (theme.digitalV2) {
    return ThemeName.ILLUSTRATED2
  } else if (theme.digitalV3) {
    return ThemeName.ILLUSTRATED3
  } else if (theme.digitalV4) {
    return ThemeName.ILLUSTRATED4
  }
  return undefined
}
