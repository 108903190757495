import { useTheme } from '@emotion/react'
import { RemoteContext } from 'fitify-ui/src/hooks/useRemoteConfig'
import { usePromo } from 'fitify-ui-onboarding/src/hooks/usePromo'
import { SubscriptionPlan } from 'fitify-ui-onboarding/src/types'
import { useTranslation } from 'next-i18next'
import { useContext, useMemo } from 'react'

import { EPlanTypes } from '../PlanDetail'

import { getPlanPrice } from './plan-price'

const APP_DEFAULTS = {
  hcV1: {
    title: 'payment_coach_title',
    subtitle: 'payment_coach_subtitle',
    badge: 'hc_pro_special_offer',
  },
  digitalV2: {
    title: 'payment_title',
    subtitle: 'payment_subtitle',
    badge: 'pro_special_offer',
  },
}

const getPlanPromoKeys = (promoCode: string | null) => ({
  title: `promo_${promoCode}_title`,
  subtitle: `promo_${promoCode}_subtitle`,
  badgeLabel: `promo_${promoCode}_special_offer_label`,
})

export const usePlanTitle = (plans?: SubscriptionPlan[]) => {
  const { t } = useTranslation()
  const promoCode = usePromo()
  const remoteConfig = useContext(RemoteContext)
  const theme = useTheme()

  const featuredPlan = plans?.find((plan) => {
    const planVariant = plan.type
    return planVariant && planVariant !== EPlanTypes.REGULAR
  })

  return useMemo(() => {
    const promoKeys = getPlanPromoKeys(promoCode)
    const planPrice = getPlanPrice(featuredPlan)
    const currentTheme = theme.hcV1 ? 'hcV1' : 'digitalV2'
    const content = APP_DEFAULTS[currentTheme]

    // DEFAULTS
    const defaultTitle = remoteConfig[content.title]
    const defaultSubtitle = remoteConfig[content.subtitle]
    const defaultBadge = content.badge

    let promoTitle: string = t(defaultTitle, {})
    let promoSubtitle: string = t(defaultSubtitle)
    let promoBadgeLabel: string = t(defaultBadge)

    const rcPromoTitle = remoteConfig[promoKeys.title]
    if (theme.digitalV2 || theme.digitalV3 || theme.digitalV4) {
      if (rcPromoTitle) {
        promoTitle = t(rcPromoTitle, {
          0: remoteConfig[`promo_${promoCode}_discount_percentage`],
        })
      }
    }

    if (theme.hcV1) {
      const isDayUnit = planPrice?.discountDurationUnit === 'day'
      const duration = planPrice?.discountDuration
      const discountInterval = isDayUnit && duration ? duration / 30 : duration

      const planParams = {
        0: discountInterval,
        1: planPrice?.current,
        2: planPrice?.futurePriceTotalValue,
        count: discountInterval,
      }

      const currentTitle = rcPromoTitle || defaultTitle
      promoTitle = t(currentTitle, planParams)
    }

    // Subtitle
    const rcPromoSubtitle = remoteConfig[promoKeys.subtitle]
    if (rcPromoSubtitle) {
      promoSubtitle = t(rcPromoSubtitle)
    }

    // Badge
    const rcBadgeLabel = remoteConfig[promoKeys.badgeLabel]
    if (rcBadgeLabel) {
      promoBadgeLabel = t(rcBadgeLabel)
    }

    return {
      title: promoTitle,
      subtitle: promoSubtitle,
      badge: promoBadgeLabel,
    }
  }, [theme, featuredPlan, promoCode, remoteConfig, t])
}
