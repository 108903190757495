import styled, { css, keyframes } from 'styled-components'

import { addAlpha, colors } from '../theme/colors'

import { OverlayProps } from './Overlay.Types'

export const defaultFadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const defaultFadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

export const StyledOverlay = styled.div<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100dvh;

  animation-name: ${({ show }) => (show ? defaultFadeIn : defaultFadeOut)};
  animation-duration: ${({ duration }) => duration || 500}ms;

  ${({ showOverlay }) => {
    if (showOverlay) {
      return css`
        padding: 2rem;
        background-color: ${addAlpha(colors.Black, 0.3)};
      `
    } else {
      return css`
        padding: 0;
        background-color: ${addAlpha(colors.Black, 0)};
      `
    }
  }};

  ${({ overlayIndex }) =>
    overlayIndex !== undefined &&
    css`
      z-index: ${overlayIndex};
    `};

  @media (max-width: 768px) {
    padding: 0;
  }
`
