import { UnitSystem } from 'fitify-types/src/types/common'
import { FitifyApp } from 'fitify-types/src/types/fitify'
import { User } from 'fitify-types/src/types/user'

const DG_AGE_RANGE = [10, 120]
const HC_AGE_RANGE = [18, 120]
const HEIGHT_RANGE_METRIC = [50, 280]
const HEIGHT_RANGE_IMPERIAL = [20, 110]
const WEIGHT_RANGE_METRIC = [30, 635]
const WEIGHT_RANGE_IMPERIAL = [20, 1400]
const GOAL_RANGE = [1, 3]
const FITNESS_RANGE = [1, 3]
const MAX_IMPACT_RANGE = [0, 2]
const BODY_TYPE_RANGE = [1, 4]

export const isNumberInRange = (
  range: number[],
  value?: number,
  checkInteger = false
): boolean => {
  if (typeof value !== 'number' || (checkInteger && !Number.isInteger(value))) {
    return false
  }

  return value >= range[0] && value <= range[1]
}

export const validateAge = (age: number, app: FitifyApp): boolean => {
  const ageRange = app === FitifyApp.HumanCoaching ? HC_AGE_RANGE : DG_AGE_RANGE
  return isNumberInRange(ageRange, age)
}

export const validateHeight = (
  height: number,
  unitSystem = UnitSystem.Metric
): boolean => {
  const heightRange =
    unitSystem === UnitSystem.Metric
      ? HEIGHT_RANGE_METRIC
      : HEIGHT_RANGE_IMPERIAL
  return isNumberInRange(heightRange, height)
}

export const validateWeight = (
  weight: number,
  unitSystem = UnitSystem.Metric
): boolean => {
  const weightRange =
    unitSystem === UnitSystem.Metric
      ? WEIGHT_RANGE_METRIC
      : WEIGHT_RANGE_IMPERIAL
  return isNumberInRange(weightRange, weight)
}

export const validateGender = (
  gender: User.Profile.Gender | string,
  app: FitifyApp
): boolean => {
  if (app === FitifyApp.HumanCoaching) {
    return Object.values(User.Profile.Gender).includes(
      gender as User.Profile.Gender
    )
  }

  return [User.Profile.Gender.Female, User.Profile.Gender.Male].includes(
    gender as User.Profile.Gender
  )
}

export const validateGoal = (goal: number): boolean => {
  return isNumberInRange(GOAL_RANGE, goal, true)
}

export const validateFitness = (fitness: number): boolean => {
  return isNumberInRange(FITNESS_RANGE, fitness, true)
}

export const validateMaxImpact = (maxImpact: number): boolean => {
  return isNumberInRange(MAX_IMPACT_RANGE, maxImpact, true)
}

export const validateBodyType = (bodyType: number): boolean => {
  return isNumberInRange(BODY_TYPE_RANGE, bodyType, true)
}

export const validateProblemAreas = (
  problemAreas: User.Profile.ProblemArea[]
): boolean => {
  return (
    problemAreas.length > 0 &&
    problemAreas.every(
      (area) => Object.values(User.Profile.ProblemArea).indexOf(area) !== -1
    )
  )
}

export const validateUnits = (units: UnitSystem): boolean => {
  return Object.values(UnitSystem).includes(units)
}
