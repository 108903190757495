import { HTMLAttributes } from 'react'

import { StyledChip } from './Chip.Styled'

export interface ChipStyledProps {
  variant?: 'green' | 'yellow'
}

export interface ChipProps
  extends HTMLAttributes<HTMLSpanElement>,
    ChipStyledProps {}

export const Chip: React.FC<ChipProps> = ({ children, ...rest }) => {
  return <StyledChip {...rest}>{children}</StyledChip>
}
