import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { BREAKPOINTS } from '../../utils/styled'
import { StyledCircleButton } from '../Button'
import { Stack } from '../Stack'

export const StyledHeader = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: var(--z-index-header);
`

export const StyledHeaderContainer = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--h-header);
  padding: 0 3rem;
  background: var(--c-header);

  @media ${BREAKPOINTS.MD.lte} {
    height: var(--h-header-mobile);
    padding: 0 1rem;
  }

  ${(props) => {
    switch (true) {
      case props.theme.digitalV2:
        return css`
          background: var(--c-blue-base);
        `
      default:
        return null
    }
  }}
`

export const StyledPromoRedirect = styled(StyledCircleButton)`
  position: absolute;
  left: 15px;
`

export const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: var(--w-content);
  margin: 0 auto;
`

export const StyledLogo = styled.img<{
  responsive?: { width: number; height: number }
}>`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  ${({ responsive }) => {
    if (responsive?.width && responsive?.height) {
      return css`
        @media ${BREAKPOINTS.MD.lte} {
          width: ${responsive.width}px;
          height: ${responsive.height}px;
        }
      `
    }

    return null
  }}
`

/* USER MENU */
export const StyledUserContainer = styled.div`
  position: absolute;
  max-width: 20%;
  right: 40px;
  display: flex;
  align-items: center;
  gap: 8px;

  @media ${BREAKPOINTS.MD.lte} {
    right: 16px;
  }
`

export const StyledUserAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;

  @media ${BREAKPOINTS.MD.lte} {
    display: none;
  }
`

export const StyledUserData = styled(Stack)`
  flex-direction: column;
`

export const StyledUserName = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--c-text-secondary);
`

export const StyledUserEmail = styled(StyledUserName)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledLogoutLink = styled.a`
  align-self: flex-end;
  font-style: normal;
  font-weight: ${(props) => (props.theme.digitalV4 ? 400 : 500)};
  font-size: 14px;
  color: ${(props) =>
    props.theme.digitalV4 ? 'var(--c-blue75)' : 'var(--c-gray500)'};
  text-decoration: none;

  cursor: pointer;
`
