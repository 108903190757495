import {
  StyledInputWrapper,
  StyledInput,
  StyledLabel,
} from './AnimatedInput.Styled'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  // eslint-disable-next-line react/boolean-prop-naming
  invalid?: boolean
  value: string | number
}

/**
 * @description
 * Input design introduced in Digital Onboarding prototype 2.0.
 * @example
 * <Input name={'input-name'} placeholder={'NameInput'} />
 * @returns EmotionJSX.Element
 */
export const AnimatedInput = ({
  type = 'text',
  name,
  placeholder,
  invalid,
  value,
  onChange,
  ...rest
}: Props) => {
  return (
    <StyledInputWrapper>
      <StyledInput
        type={type}
        value={value}
        name={name}
        hasValue={value !== ''}
        invalid={invalid}
        onChange={onChange}
        {...rest}
      />
      <StyledLabel htmlFor={name}>{placeholder}</StyledLabel>
    </StyledInputWrapper>
  )
}
