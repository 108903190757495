import { HTMLAttributes, MouseEvent } from 'react'

import { StyledOverlay } from './Overlay.Styled'
import { OverlayProps } from './Overlay.Types'

type Props = OverlayProps & HTMLAttributes<HTMLDivElement>

export const Overlay = ({
  children,
  duration,
  overlayIndex,
  preventClickEvents = false,
  show,
  showOverlay,
  ...props
}: Props) => {
  const handlePreventClick = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <StyledOverlay
      {...props}
      duration={duration}
      overlayIndex={overlayIndex}
      show={show}
      showOverlay={showOverlay}
      {...(preventClickEvents && {
        onClick: handlePreventClick,
        onContextMenu: handlePreventClick,
        onDoubleClick: handlePreventClick,
      })}
    >
      {children}
    </StyledOverlay>
  )
}
