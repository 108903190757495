import { useState, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'

export const useViewport = () => {
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const [bottom, setBottom] = useState<number>(0)

  useEffect(() => {
    if (typeof window === 'undefined' && !isMobileOnly) return

    const setSizes = () => {
      const viewport = window.visualViewport

      if (viewport) {
        const wHeight = window.innerHeight
        const vpHeight = viewport.height
        const vpWidth = viewport.width

        setHeight(vpHeight)
        setWidth(vpWidth)
        setBottom(wHeight - vpHeight)
      }
    }

    setSizes()

    window.visualViewport?.addEventListener('resize', setSizes)
    window.visualViewport?.addEventListener('scroll', setSizes)
    return () => {
      window.visualViewport?.removeEventListener('scroll', setSizes)
      window.visualViewport?.removeEventListener('resize', setSizes)
    }
  }, [])

  return { width, height, bottom }
}
