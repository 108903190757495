import { EPlanTypes } from '../components/Plan/PlanDetail'

export enum ESubscriptionVariant {
  COACH = 'coach',
  GIFT = 'gift',
  PLAIN = 'plain',
}

export enum PlanInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  HALFYEAR = 'halfyear',
  YEAR = 'year',
  LIFETIME = 'lifetime',
}

export interface IPlanPrices {
  current: string
  total: string
  original: string
  futurePriceTotalValue?: string
  discount?: number
  discountDuration?: number
  discountDurationUnit?: string
  discountInterval?: number
  discountIntervalUnit?: string
  variant?: string
}

export interface IPlanSku {
  path: string
  type?: PlanInterval
}

export type IPaywallQuestionsType = 'regular' | 'with-numbers'

export interface IPaywallQuestions {
  type: IPaywallQuestionsType
  title: string
  questions: { title: string; response: string }[]
}

export interface SubscriptionPlan {
  interval?: PlanInterval
  locale: string
  currency?: string
  priceTotalValue?: number
  totalValue?: number
  futurePriceTotalValue?: number
  formattedTotalValue?: string
  intervalUnit?: string
  intervalLength?: number
  discountDurationLength?: number
  discountPercentValue?: number
  discountDurationUnit?: string
  path?: string
  type: EPlanTypes
}

export interface Subscription {
  plans: SubscriptionPlan[] | null
  activePlan: string | null
  onPlanChange: (value: string) => void
  onSubmit: () => void
}
