import { ChangeEvent, useState } from 'react'

import {
  StyledInput,
  StyledInputContainer,
  StyledInputLabel,
} from './Input.Styled'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  // eslint-disable-next-line react/boolean-prop-naming
  invalid?: boolean
}

/**
 * @description
 * Input design introduced in Digital Onboarding prototype 2.0.
 * @example
 * <Input name={'input-name'} placeholder={'NameInput'} />
 * @returns EmotionJSX.Element
 */
export const Input = ({
  type = 'text',
  name,
  placeholder,
  invalid,
  onChange,
  ...rest
}: Props) => {
  const [value, setValue] = useState('')

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)

    if (onChange) {
      onChange(e)
    }
  }

  return (
    <StyledInputContainer invalid={invalid} hasValue={value ? true : false}>
      <StyledInput
        type={type}
        value={value}
        onChange={handleOnChange}
        {...rest}
      />
      <StyledInputLabel htmlFor={name}>{placeholder}</StyledInputLabel>
    </StyledInputContainer>
  )
}
