/* eslint-disable react/destructuring-assignment */
import { getAuth } from 'firebase/auth'
import { Component, ReactNode } from 'react'
import StackdriverErrorReporter from 'stackdriver-errors-js'

interface Props {
  isProduction: boolean
  apiKey: string
  projectId: string
  service: string
  version: string
  children?: ReactNode
}

interface State {
  hasError: boolean
  error: string
}

export default class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    error: '',
  }

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: _.message }
  }

  componentDidCatch(error: Error) {
    if (this.props.isProduction) {
      const firebaseAuth = getAuth()

      const errorHandler = new StackdriverErrorReporter()
      errorHandler.start({
        key: this.props.apiKey,
        projectId: this.props.projectId,
        service: this.props.service,
        version: this.props.version,
      })

      if (firebaseAuth.currentUser) {
        errorHandler.setUser(firebaseAuth.currentUser.uid)
      }
      void errorHandler.report(error)
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Sorry.. there was an error</h1>
    }

    return this.props.children
  }
}
