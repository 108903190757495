import styled, { css } from 'styled-components'

import { colors, StyledIcon } from '../..'

import {
  CloseButtonTypes,
  ModalCloseProps,
  ModalContainerProps,
  ModalSizeTypes,
} from './Modal.Types'

export const StyledModalContainer = styled.div<ModalContainerProps>`
  position: relative;
  min-height: 50px;
  max-height: calc(100dvh - 80px);
  padding: 20px;
  overflow: auto;
  background-color: ${colors.White};
  border-radius: ${(props) => props.borderRadius}px;

  @media (max-width: 768px) {
    height: 100dvh;
    width: 100vw;
    min-width: unset;
    min-height: unset;
    max-height: unset;
    max-width: unset;
    border-radius: 0px;

    padding: 12px 24px 20px;
  }

  ${(props) => {
    switch (props.size) {
      case ModalSizeTypes.SMALL:
        return css`
          width: 356px;
        `
      case ModalSizeTypes.MEDIUM:
        return css`
          width: 480px;
        `
      case ModalSizeTypes.LARGE:
        return css`
          width: 860px;
        `
      case ModalSizeTypes.FULL:
        return css`
          width: 100%;
          height: 100%;
          max-height: initial;
          border-radius: 0;
          padding: 0;

          @media (max-width: 768px) {
            padding: 0;
          }
        `
      default:
        return css`
          width: 100%;
          max-width: 860px;
        `
    }
  }}
`
export const StyledModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;

  h3 {
    margin: 0;
    max-width: 90%;
  }
`

export const StyledModalClose = styled.button<ModalCloseProps>`
  position: absolute;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 24px;
  right: 24px;
  padding: 0;
  background-color: ${colors.Gray200};
  width: 48px;
  height: 48px;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  transition: 0.3s ease all;

  ${StyledIcon} {
    color: ${colors.Black};
  }

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    top: 18px;
    width: 24px;
    height: 24px;
    margin-top: 0;
    background-color: ${colors.White};

    ${StyledIcon} {
      color: ${colors.Black};
      width: 24px;
      height: 24px;
    }
  }

  ${({ variant }) =>
    variant === CloseButtonTypes.WHITE
      ? css`
          margin-top: 0;
          background-color: transparent;

          ${StyledIcon} {
            width: 24px;
            height: 24px;
          }
        `
      : null}
`

export const StyledModalLogo = styled.div<{ showLogo: boolean }>`
  display: ${(props) => (props.showLogo ? 'block' : 'none')};

  @media (max-width: 768px) {
    display: block;
  }
`
