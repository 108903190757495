import { UnitSystem } from 'fitify-types/src/types/common'
import {
  kilogramsToPounds,
  poundsToKilograms,
} from 'fitify-utils/src/converters'
import { KeyboardEvent } from 'react'

export const KG_PER_LBS = 0.45359237
export const CM_PER_INCH = 2.54
export const INCH_PER_FEET = 12

export function cmToFeetAndInches(cm: number) {
  const inchesTotal = cm / CM_PER_INCH
  let feet = Math.floor(inchesTotal / INCH_PER_FEET)
  let inches = Math.round(inchesTotal % INCH_PER_FEET)

  if (inches === 12) {
    feet += 1
    inches = 0
  }

  return {
    feet,
    inches,
    inchesTotal,
  }
}

export function feetAndInchesToCm(feet: number, inches: number): number {
  // Calculate the height in inches
  const feetValue = feet || 0
  const inchesValue = inches || 0

  const totalInches = feetValue * INCH_PER_FEET + inchesValue
  return totalInches * CM_PER_INCH
}

export const blockInvalidNumberCharacters = (
  e: KeyboardEvent<HTMLInputElement>,
  disableDecimalPoint?: boolean
) => {
  const invalidChars = disableDecimalPoint
    ? ['-', '+', 'e', 'E', ',', '.']
    : ['-', '+', 'e', 'E']
  return invalidChars.includes(e.key) && e.preventDefault()
}

export const convertWeight = (
  weight?: number,
  toUnit?: UnitSystem
): number | undefined => {
  if (!weight || !toUnit) return undefined

  if (toUnit === UnitSystem.Metric) {
    return Math.round(poundsToKilograms(weight, 0))
  } else {
    return Math.round(kilogramsToPounds(weight))
  }
}
