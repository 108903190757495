import { createContext, useContext } from 'react'

export type ICacheValue = unknown
export interface ICacheData {
  [key: string]: ICacheValue
}

export const CacheContext = createContext<{
  data: ICacheData
  set: (key: string, value: ICacheValue) => void
}>({
  data: {},
  set: () => void 0,
})

export function useMemoryCache<T>(key: string) {
  const { data, set } = useContext(CacheContext)
  return {
    data: data[key] as T,
    set: (value: T) => set(key, value),
  }
}
