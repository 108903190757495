import { UnitSystem } from 'fitify-types/src/types/common'
import {
  RemoteConfig,
  RemoteContext,
} from 'fitify-ui/src/hooks/useRemoteConfig'
import { getNavigatorLocale } from 'fitify-ui-onboarding'
import { useUserContext } from 'fitify-ui-onboarding/src/contexts/UserContext'
import {
  useInitNavigation,
  useAuthEvent,
  usePageEvent,
  usePreload,
  useStepValidation,
  useUserProperties,
} from 'fitify-ui-onboarding/src/hooks'
import { useInitialPromo } from 'fitify-ui-onboarding/src/hooks/usePromo'
import { IMPERIAL_UNITS_LOCALES } from 'fitify-ui-onboarding/src/types'
import { AppProps } from 'next/app'
import { useEffect, useMemo } from 'react'

import { useUserRedirect } from 'hooks/useUserRedirect'
import { ALL_PAGES_MAP, ONBOARDING_PAGES_MAP } from 'utils/routes'
import { DataContext, useAppState } from 'utils/state'

export function PlanApp({
  Component,
  pageProps,
  config,
}: Pick<AppProps, 'Component' | 'pageProps'> & { config: RemoteConfig }) {
  const { data, set, clear, isLoading } = useAppState()

  const { user, isLoggedIn, userProfile } = useUserContext()

  useUserRedirect()

  // Init navigation of onboarding pages and return allowed keys for validation
  const allowedRoutes = useInitNavigation(ONBOARDING_PAGES_MAP, config)

  // Validates previous step
  useStepValidation(allowedRoutes, data, isLoading)

  // Logs page events
  useAuthEvent(user)
  useUserProperties(data, userProfile)
  usePageEvent(ALL_PAGES_MAP)
  usePreload()

  // Initial promo
  useInitialPromo(isLoggedIn, config)

  // Set default imperial or metric based on locale
  useEffect(() => {
    if (!data.units) {
      const locale = getNavigatorLocale()
      const hasImperialUnits = IMPERIAL_UNITS_LOCALES.includes(locale)

      set({ units: hasImperialUnits ? UnitSystem.Imperial : UnitSystem.Metric })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataContextProviderValue = useMemo(
    () => ({ data, set, clear }),
    [data, set, clear]
  )
  return (
    <RemoteContext.Provider value={config}>
      <DataContext.Provider value={dataContextProviderValue}>
        <Component {...pageProps} />
      </DataContext.Provider>
    </RemoteContext.Provider>
  )
}
