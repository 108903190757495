export enum FitifyApp {
  Yoga = 'yoga',
  Workouts = 'workouts',
  HumanCoaching = 'hc',
}

export type FitifyPlatform = 'ios' | 'android'

export enum FitifyAppBundleId {
  AndroidDg = 'com.fitifyworkouts.bodyweight.workoutapp',
  AndroidYoga = 'com.fitifyworkouts.yoga',
  AndroidHc = 'com.fitifyworkouts.personaltrainer',
  IosDg = 'com.fitifyworkouts.bodyweight.workouts',
  IosHc = 'com.fitifyapps.BWCardio',
}
