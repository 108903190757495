import { useTheme } from '@emotion/react'
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  type User as AuthUser,
} from 'firebase/auth'
import { User } from 'fitify-types/src/types/user'
import { useRouter } from 'next/router'
import {
  Context,
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { fetchUserProfile, usePrevious } from '../..'

export type UserAuthData = {
  user: AuthUser | null
  userProfile: User | null
  isLoggedIn: boolean
  isAuthLoading: boolean
  isUserPromoEligible: boolean
  logout: () => Promise<void>
}

export const UserContext: Context<UserAuthData> = createContext<UserAuthData>({
  user: null,
  userProfile: null,
  isLoggedIn: false,
  isAuthLoading: true,
  isUserPromoEligible: false,
  logout: async () => Promise.resolve(undefined),
})

export const UserContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<AuthUser | null>(null)
  const previousUser = usePrevious<AuthUser | null>(user)
  const [userProfile, setUserProfile] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAuthStateLoaded, setIsAuthStateLoaded] = useState<boolean>(false)
  const [isUserPromoEligible, setIsUserPromoEligible] = useState<boolean>(true)

  const router = useRouter()
  const theme = useTheme()

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    return onAuthStateChanged(getAuth(), (authUser) => {
      if (authUser) {
        setUser(authUser)
      } else {
        // Reset state if no user is authenticated
        setUser(null)
        setUserProfile(null)
      }
      setIsAuthStateLoaded(true)
    })
  }, [router.isReady])

  // Fetch user profile
  useEffect(() => {
    if (
      user?.uid &&
      (!userProfile || previousUser?.uid !== user?.uid) &&
      !isLoading
    ) {
      const fetchProfile = async () => {
        setIsLoading(true)
        const profile = await fetchUserProfile(user.uid)
        setUserProfile(profile)
        setIsLoading(false)
      }

      void fetchProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(userProfile), user?.uid, previousUser?.uid, isLoading])

  //Check user's elibility to use promo
  useEffect(() => {
    const checkEligibility = async () => {
      const response = await fetch(
        `/api/check-user-promo-eligibility?userId=${user?.uid}`
      )
      const { isEligible } = await response.json()
      setIsUserPromoEligible(isEligible)
    }

    if (theme.hcV1 && user?.uid) {
      void checkEligibility()
    }
  }, [theme.hcV1, user?.uid])

  const value = useMemo(
    () => ({
      user,
      userProfile,
      isLoggedIn: !!user,
      isAuthLoading: isLoading || !isAuthStateLoaded,
      isUserPromoEligible,
      logout: async () => {
        await signOut(getAuth())
        setUser(null)
        setUserProfile(null)
        setIsLoading(false)
      },
    }),
    [isAuthStateLoaded, isLoading, isUserPromoEligible, user, userProfile]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }
  return context
}
