import { ContentContainer, PageContainer } from '../Container'
import { Text } from '../Typography'

const ErrorPage = ({
  error,
  title,
  subtitle,
  cta,
}: {
  error: string
  title: string
  subtitle: string
  cta: JSX.Element
}) => {
  return (
    <PageContainer>
      <ContentContainer align="center" xs="1.5rem">
        <Text as={'h1'} size="error-title" weight="500">
          {error}
        </Text>
        <Text as={'h2'} size="xl" weight="500" textAlign="center">
          {title}
        </Text>
        <Text as={'p'}>{subtitle}</Text>

        {cta}
      </ContentContainer>
    </PageContainer>
  )
}

export default ErrorPage
