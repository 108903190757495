import Image, { ImageLoaderProps, ImageProps } from 'next/image'

const staticFitifyLoader = (resolverProps: ImageLoaderProps) => {
  return `${resolverProps.src}?width=${resolverProps.width}`
}

export const FitifyImage = ({ quality = 75, alt, ...rest }: ImageProps) => {
  return (
    <Image {...{ quality }} alt={alt} {...rest} loader={staticFitifyLoader} />
  )
}
