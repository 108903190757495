import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { BREAKPOINTS } from '../utils/styled'

import { Stack } from './Stack'

export const HeadingContainer = styled(Stack)`
  ${(props) => {
    switch (true) {
      case props.theme.digitalV2:
        return css`
          max-width: 80%;
          align-items: center;

          * {
            text-align: center;
          }

          @media ${BREAKPOINTS.MD.lte} {
            max-width: none;
          }
        `
      default:
        return css`
          max-width: var(--w-content);
          align-items: left;
        `
    }
  }}
`

export const ContentContainer = styled(Stack)<{ removePadding?: boolean }>`
  flex: 1;
  max-width: var(--w-content);

  ${(props) => {
    if (props.removePadding) return null

    switch (true) {
      case props.theme.digitalV2:
        return css`
          align-items: center;
          text-align: center;
          padding-top: 3rem;
          margin: 0 auto;
        `
      default:
        return css`
          padding-top: 2rem;

          @media ${BREAKPOINTS.MD.lte} {
            padding-top: 1.25rem;
          }
        `
    }
  }}
`

export const PageContainer = styled(Stack)<{ isWide?: boolean }>`
  align-items: center;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  max-width: var(--w-content-wide);

  @media ${BREAKPOINTS.MD.lte} {
    max-width: var(--w-content);
  }
`

export const FormContainer = styled.form`
  position: relative;
  width: 100%;

  flex: 1;
  max-width: var(--w-content);

  display: flex;
  break-inside: avoid;
  flex-direction: column;
  justify-content: space-between;

  ${(props) => {
    switch (true) {
      case props.theme.hcV1:
        return css`
          padding-top: 4rem;

          @media ${BREAKPOINTS.MD.lte} {
            padding-top: 1.25rem;
          }
        `

      case props.theme.digitalV2:
        return css`
          padding-top: 3rem;
        `
      default:
        return css`
          padding-top: 2rem;

          @media ${BREAKPOINTS.MD.lte} {
            padding-top: 1.25rem;
          }
        `
    }
  }}
`
