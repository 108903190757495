import { useTheme } from '@emotion/react'
import { RemoteContext } from 'fitify-ui/src/hooks/useRemoteConfig'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useContext, useEffect } from 'react'

import { authHelper } from '../helpers'

export const useAuthRedirect = (isLoggedIn: boolean): void => {
  const router = useRouter()
  const theme = useTheme()
  const config = useContext(RemoteContext)

  // useEffect to handle the side-effect of redirection
  useEffect(() => {
    // Exit early if configuration, router, or login status is not ready
    if (!config || !router.isReady || !isLoggedIn) return

    // Function to handle redirection logic
    const handleRedirect = async (): Promise<void> => {
      const app = theme.hcV1 ? 'hc' : 'workouts'
      // Handle new user registration logic
      await authHelper.handleNewUser(config, null, app)

      const nextPage = router.query.next as string | undefined
      const redirectPath = nextPage
        ? nextPage
        : constructRedirectPath(router.query)
      void router.push(redirectPath)
    }

    void handleRedirect()
  }, [config, router, theme.hcV1, isLoggedIn])
}

function constructRedirectPath(query: ParsedUrlQuery): string {
  // If no query parameters, return the home path
  if (Object.keys(query).length === 0) return '/'

  // Initialize an object to hold the query parameters
  const queryParams: Record<string, string | string[]> = {}
  Object.keys(query).forEach((key) => {
    const value = query[key]
    queryParams[key] = Array.isArray(value) ? value.join(',') : value || ''
  })

  const searchParams = new URLSearchParams(
    queryParams as Record<string, string>
  )
  return `/?${searchParams.toString()}`
}
