import { useLayoutEffect, useState } from 'react'

interface WindowSizeType {
  innerWidth: number
  innerHeight: number
}

const useWindowSize = (): WindowSizeType => {
  const [windowSize, setWindowSize] = useState<WindowSizeType>({
    innerWidth: 0,
    innerHeight: 0,
  })
  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}

export default useWindowSize
