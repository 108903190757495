import styled from '@emotion/styled'

interface ProgressBarProps {
  percentage?: number
}

const ProgressLine = styled.div<ProgressBarProps>`
  width: ${(props) => props.percentage || 0}%;
  height: 100%;
  background: var(--c-progress-bar-line);
`

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 0.125rem;
  background: var(--c-progress-bar);
`

export const ProgressBar = ({ percentage }: ProgressBarProps) => {
  return (
    <ProgressBarWrapper>
      <ProgressLine percentage={percentage} />
    </ProgressBarWrapper>
  )
}
