import { useTheme } from '@emotion/react'
import {
  getPlanPrice,
  getWeeklyPlanPrice,
} from 'fitify-ui-onboarding/src/components/Plan/utils'
import {
  formatCurrency,
  resolveMonthlyPrice,
  resolvePercentageSaved,
} from 'fitify-ui-onboarding/src/components/Plan/utils/plan-price'
import { usePromo } from 'fitify-ui-onboarding/src/hooks/usePromo'
import {
  PlanInterval,
  ESubscriptionVariant,
  SubscriptionPlan,
} from 'fitify-ui-onboarding/src/types'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'

import { Badge } from './Badge'
import {
  SavePercentBadge,
  StyledMonthlyPriceInfo,
  StyledPlanListItem,
  StyledPlanListItemContent,
  StyledPlanListItemContentBlock,
  StyledPlanListItemIndicator,
  StyledPlanListItemName,
  StyledPlanListItemPriceInfo,
  StyledPlanListItemWrapper,
  StyledPlanListMonthlyPrice,
  StyledPlanListPrice,
  StyledPriceWrapper,
} from './Plan.Styled'

export const PlanListItemIndicator = ({ isActive }: { isActive?: boolean }) => {
  return (
    <StyledPlanListItemIndicator isActive={isActive}>
      {isActive ? (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.5 10.5L0.5 6.5L2 5L4.5 7.5L10.5 1.5L12 3L4.5 10.5Z"
            fill="#212121"
          />
        </svg>
      ) : null}
    </StyledPlanListItemIndicator>
  )
}

export interface PlanListItemProps {
  plan: SubscriptionPlan
  variant?: ESubscriptionVariant
  isActive?: boolean
  onPlanChange: (value: string) => void | undefined
  regularMonthlyPrice?: number
}

export const PlanListItem = ({
  plan,
  variant,
  isActive,
  onPlanChange,
  regularMonthlyPrice,
}: PlanListItemProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const promoCode = usePromo()
  const prices = theme.hcV1 ? getPlanPrice(plan) : getWeeklyPlanPrice(plan)

  const isGiftPlan = variant === ESubscriptionVariant.GIFT
  const isPlainPlan = variant === ESubscriptionVariant.PLAIN
  const showDiscountPrice =
    prices?.discount && prices?.current !== prices?.original

  // Common for discounts and free trials (which do not have discount value defined)
  const showDiscountBadge =
    !theme.hcV1 &&
    (prices?.discount !== undefined || prices?.discountDuration !== undefined)

  const getPlanTitle = (plan: PlanInterval) => {
    const prefix = theme.hcV1 ? 'hc_pro' : 'pro_plan'

    if (!theme.hcV1 && isGiftPlan) {
      switch (plan) {
        case 'year':
          return t('pro_one_year_membership')
        case 'lifetime':
          return t('pro_lifetime_membership')
        default:
          return ''
      }
    } else {
      switch (plan) {
        case 'week':
          return t(`${prefix}_weekly`)
        case 'month':
          return t(`${prefix}_monthly`)
        case 'year':
          return t(`${prefix}_yearly`)
        case 'quarter':
          return t(`${prefix}_quarterly`)
        default:
          return ''
      }
    }
  }

  const renderPriceText = useCallback(
    (price?: string) => {
      if (!isGiftPlan && !theme.hcV1) {
        return t('pro_weekly_price', { 0: price })
      } else {
        return price
      }
    },
    [t, theme, isGiftPlan]
  )

  const renderPriceTags = useCallback(() => {
    if (prices?.discount) {
      if (theme.hcV1) {
        return (
          <StyledPriceWrapper>
            <StyledPlanListPrice>
              {renderPriceText(prices?.current)}
            </StyledPlanListPrice>

            {!isPlainPlan ? (
              <StyledPlanListPrice variant={'discount'}>
                {renderPriceText(prices?.futurePriceTotalValue)}
              </StyledPlanListPrice>
            ) : null}
          </StyledPriceWrapper>
        )
      } else {
        return (
          <StyledPriceWrapper direction="column">
            <StyledPlanListPrice isActive={isActive}>
              {renderPriceText(prices?.current)}
            </StyledPlanListPrice>

            {prices.current !== prices.original ? (
              <StyledPlanListPrice variant={'discount'} isActive={isActive}>
                {renderPriceText(prices?.original)}
              </StyledPlanListPrice>
            ) : null}
          </StyledPriceWrapper>
        )
      }
    }

    return (
      <StyledPlanListPrice isActive={isActive}>
        {renderPriceText(isGiftPlan ? prices?.total : prices?.original)}
      </StyledPlanListPrice>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices, theme, variant])

  const shouldMonthlyPriceInfoBeShown = useMemo(() => {
    const shouldDisplay =
      theme.hcV1 &&
      regularMonthlyPrice &&
      prices?.discountIntervalUnit !== PlanInterval.MONTH
    return shouldDisplay
  }, [prices?.discountIntervalUnit, regularMonthlyPrice, theme.hcV1])

  return (
    <StyledPlanListItem
      onClick={() => onPlanChange(plan.path || '')}
      isActive={isActive}
    >
      <StyledPlanListItemWrapper>
        <PlanListItemIndicator isActive={isActive} />
        <StyledPlanListItemContent>
          <StyledPlanListItemContentBlock>
            <StyledPlanListItemPriceInfo>
              {!isPlainPlan ? (
                <>
                  {!showDiscountPrice &&
                  !prices?.discount &&
                  prices?.current !== prices?.original ? (
                    <StyledPlanListMonthlyPrice>
                      {t('hc_pro_paywall_x_per_month', { 0: prices?.current })}
                    </StyledPlanListMonthlyPrice>
                  ) : null}

                  {plan.interval ? (
                    <>
                      {showDiscountBadge ? (
                        <Badge
                          planType={plan.interval}
                          discountDuration={prices?.discountDuration}
                          discountAmount={prices?.discount || 0}
                          promoCode={promoCode}
                        />
                      ) : null}

                      <StyledPlanListItemName isActive={isActive}>
                        {getPlanTitle(plan.interval)}
                      </StyledPlanListItemName>
                    </>
                  ) : null}
                </>
              ) : null}

              {renderPriceTags()}
            </StyledPlanListItemPriceInfo>
            {theme.hcV1 &&
            plan.interval &&
            plan.priceTotalValue &&
            shouldMonthlyPriceInfoBeShown ? (
              <StyledMonthlyPriceInfo>
                {t('hc_pro_paywall_x_per_month', {
                  0: formatCurrency(
                    resolveMonthlyPrice(
                      plan.interval,
                      plan.priceTotalValue || plan.futurePriceTotalValue || 0,
                      plan.intervalLength || 1
                    ),
                    plan.currency ?? 'USD',
                    plan.locale,
                    2
                  ),
                })}
                <SavePercentBadge>
                  {t('pro_paywall_pricing_save', {
                    0: resolvePercentageSaved(
                      plan.interval,
                      plan.priceTotalValue,
                      regularMonthlyPrice,
                      plan.intervalLength || 1
                    ),
                  })}
                </SavePercentBadge>
              </StyledMonthlyPriceInfo>
            ) : null}
          </StyledPlanListItemContentBlock>
        </StyledPlanListItemContent>
      </StyledPlanListItemWrapper>
    </StyledPlanListItem>
  )
}
