import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const StyledBadge = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 8px;
  padding: 0.3em 0.6em;
  min-width: max-content;

  ${(props) => {
    switch (true) {
      case props.theme.digitalV2:
      case props.theme.digitalV3:
      case props.theme.digitalV4:
        return css`
          position: absolute;
          top: -11px;
          left: 50%;
          right: 50%;
          transform: translate(-50%);
          color: var(--c-white);
          background: linear-gradient(
            180deg,
            var(--c-pink500) 0%,
            var(--c-pink400) 100%
          );
        `
      default:
        return null
    }
  }}

  ${(props) =>
    props.theme.digitalV4 &&
    css`
      top: -26px;
      background: var(--c-pink400);
    `}
`
