import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { User } from 'fitify-types/src/types/user'

export const fetchUserProfile = async (uid: string) => {
  try {
    const firestore = getFirestore()
    const snapshot = await getDoc(doc(firestore, 'users', uid))

    if (snapshot.exists()) {
      return snapshot.data() as User
    }
  } catch (error) {
    console.error(error)
  }

  return null
}
