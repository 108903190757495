import { useRef, useEffect, useState, useCallback } from 'react'

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export function useIsMounted() {
  const mounted = useRef<boolean>()

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  return mounted
}

export function useLoadingCallback(
  callback: (...args: any[]) => void | Promise<void>,
  defaultValue?: boolean
): [typeof callback, boolean] {
  const [loading, setLoading] = useState<boolean>(defaultValue ?? false)
  const isMounted = useIsMounted()

  const loadingCallback = useCallback(
    (...args: any[]) => {
      setLoading(true)
      void (async () => {
        try {
          await callback(...args)
        } finally {
          if (isMounted.current) setLoading(false)
        }
      })()
    },
    [callback, isMounted]
  )

  return [loadingCallback, loading]
}
