import { css, keyframes } from '@emotion/react'

import { Stack } from './Stack'

const DEFAULT_DURATION = 1.4
const DEFAULT_SIZE = 'md'
const DEFAULT_COLOR = 'gray400'
const DEFAULT_ALIGN = 'center'
const SIZE_MAP = {
  sm: [24, 24, 72, 3],
  md: [66, 66, 200, 6],
  lg: [90, 90, 280, 6],
}

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg'
  align?: 'flex-start' | 'center' | 'flex-end'
  color?: string
  duration?: number
}

export function Spinner({
  size = DEFAULT_SIZE,
  align = DEFAULT_ALIGN,
  color = DEFAULT_COLOR,
  duration = DEFAULT_DURATION,
}: SpinnerProps) {
  const [width, height, offset, stroke] = SIZE_MAP[size]

  const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `

  const dash = keyframes`
    0% {
      stroke-dashoffset: ${offset};
    }
    50% {
      stroke-dashoffset: ${offset / 4};
      transform: rotate(90deg);
    }
    100% {
      stroke-dashoffset: ${offset};
      transform: rotate(370deg);
    }
  `

  const animationStyle = css`
    animation: ${rotate} ${duration}s linear infinite;
  `

  return (
    <Stack
      style={{
        alignItems: align,
      }}
    >
      <svg
        css={animationStyle}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
        style={{ color: `var(--c-${color})` }}
      >
        <circle
          css={css`
            stroke-dasharray: ${offset};
            stroke-dashoffset: 0;
            transform-origin: center;
            animation: ${dash} ${duration}s ease-in-out infinite;
          `}
          fill="none"
          strokeWidth={stroke}
          strokeLinecap="round"
          stroke="currentColor"
          cx={width / 2}
          cy={height / 2}
          r={width / 2 - 3}
        />
      </svg>
    </Stack>
  )
}
