export type TextAlignment = 'left' | 'center' | 'right'

export interface StyledAsProps {
  as?: React.ElementType | keyof JSX.IntrinsicElements
  color?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'error-title'
  weight?: '400' | '500' | '600' | '700' | '800' | '900'
  textAlign?: TextAlignment
  textTransform?: 'lowercase' | 'uppercase' | 'capitalize'
}

function getMediaQuery(size: number) {
  class MediaQuery {
    size = 0
    gte = ''
    lte = ''

    constructor(size: number) {
      this.size = size
      this.gte = `(min-width: ${this.size}px)`
      this.lte = `(max-width: ${this.size}px)`
    }
  }

  return new MediaQuery(size)
}

export const BREAKPOINTS = {
  XS: getMediaQuery(0),
  SM: getMediaQuery(320),
  SM2: getMediaQuery(380),
  MD: getMediaQuery(768),
  LG: getMediaQuery(1024),
  XL: getMediaQuery(1440),
}

export function pxToRem(px: number): number {
  return px / 16
}

export function colorOpacity(hex: string, alpha: number) {
  return `color-mix(
            in srgb,
            ${hex} ${alpha}%,
            transparent
          )`
}
