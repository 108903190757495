import { css, type Theme } from '@emotion/react'
import { BREAKPOINTS } from 'fitify-ui-onboarding/src/utils/styled'

export const coreElements = (theme: Theme) => {
  let themeBase
  const base = css`
    *,
    *:before,
    *:after {
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
    }

    img {
      pointer-events: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  `

  switch (true) {
    case theme.hcV1:
      themeBase = css`
        body {
          margin: 0;
          padding: 0;
          color: var(--c-gray700);
          background: var(--c-gray100);
          font-family: var(--f-text);
        }
      `
      break

    case theme.digitalV2:
      themeBase = css`
        body {
          margin: 0;
          padding: 0;
          color: var(--c-white);
          background: var(--c-blue-base);
          font-family: var(--f-text);

          @media ${BREAKPOINTS.MD.gte} {
            &:after {
              content: '';
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: -99;

              background: url('/images/background2.webp') !important;
              background-repeat: no-repeat !important;
              background-size: 100% !important;
              background-position: top center !important;
              background-color: #082662 !important;
            }
          }
        }
      `
      break

    case theme.digitalV3:
      themeBase = css`
        body {
          margin: 0;
          padding: 0;
          color: var(--c-gray700);
          background: var(--c-blue0);
          font-family: var(--f-text);
        }
      `
      break

    case theme.digitalV4:
      themeBase = css`
        body {
          margin: 0;
          padding: 0;
          color: var(--c-gray700);
          background: var(--c-blue850);
          font-family: var(--f-text);
        }
      `
      break

    default:
      break
  }

  return css`
    ${base}
    ${themeBase}
  `
}
