import { css, type Theme } from '@emotion/react'

export const colors = () => css`
  --c-white: #fff;
  --c-black: #000000;

  --c-blue-border: #134a90;
  --c-blue-base: #083168;
  --c-blue0: #eff7ff;
  --c-blue50: #dfeeff;
  --c-blue75: #bfcfe3;
  --c-blue100: #9fcdff;
  --c-blue200: #60acff;
  --c-blue250: #7597c5;
  --c-blue300: #208bff;
  --c-blue400: #007aff;

  --c-blue500: #026eff;
  --c-blue550: #4875b1;
  --c-blue600: #015adc;
  --c-blue700: #0045b9;
  --c-blue800: #7e98bc;
  --c-blue850: #0c3d7d;
  --c-blue900: #001538;
  --c-blue950: #020a15;

  --c-yellow400: #ffcc00;
  --c-yellow450: #ffdc24;
  --c-yellow500: #f4bb00;
  --c-yellow700: #a97600;

  --c-pink400: #fb0071;
  --c-pink500: #d70076;

  --c-red: #ff3d00;
  --c-red500: #ef3900;

  --c-green0: #f3ffe4;
  --c-green50: #e6ffc8;
  --c-green100: #dbff9f;
  --c-green200: #c8ff71;
  --c-green300: #b8fa4e;
  --c-green400: #95eb00;
  --c-green500: #80db00;
  --c-green600: #68b800;
  --c-green700: #519300;
  --c-green800: #3f7600;
  --c-green900: #193100;
  --c-vibrant-green: #afff2d;

  --c-gray100: #f5f5f5;
  --c-gray200: #eeeeee;
  --c-gray300: #e0e0e0;
  --c-gray400: #bdbdbd;
  --c-gray500: #9e9e9e;
  --c-gray600: #757575;
  --c-gray700: #616161;
  --c-gray800: #424242;
  --c-gray900: #212121;

  --c-violet: #35eaf0;
`

export const themeColors = (theme?: Theme) => {
  if (theme?.hcV1) {
    return css`
      /* LAYOUT */
      --c-header: var(--c-white);
      --c-surface: var(--c-gray100);
      --c-error: var(--c-red400);

      /* TEXTS */
      --c-text: var(--c-black);
      --c-text-secondary: var(--c-gray700);
      --a-title-align: flex-start;

      /* BUTTONS */
      --c-primary: var(--c-green400);
      --c-primary-text: var(--c-black);
      --c-primary--hover: var(--c-green200);
      --c-primary--focus: var(--c-green200);
      --c-primary--active: var(--c-green100);
      --c-primary--disabled: var(--c-gray300);

      --c-secondary: var(--c-black);
      --c-secondary-text: var(--c-white);
      --c-secondary--hover: var(--c-gray800);
      --c-secondary--focus: var(--c-gray800);
      --c-secondary--active: var(--c-gray600);
      --c-secondary--disabled: var(--c-gray300);
      --c-secondary-color--disabled: var(--c-gray400);

      /* INPUTS */
      --c-input-border: var(--c-gray400);
      --c-input-border-focus: var(--c-gray900);
      --c-input-border-error: var(--c-red500);
      --c-input-text-color: var(--c-black);
      --c-input-placeholder-color: var(--c-gray600);
      --f-input-font-weight: 500;
      --h-input-height: 56px;

      --c-animated-input-color: var(--c-black);
      --c-animated-input-label-color: var(--c-gray600);
      --c-animated-input-border: var(--c-gray500);
      --c-animated-input-border-focus: var(--c-gray900);
      --c-animated-input-autofill: var(--c-black);

      /* HEADER */
      --c-progress-bar: var(--c-gray300);
      --c-progress-bar-line: var(--c-blue400);
    `
  }

  if (theme?.digitalV2) {
    return css`
      /* LAYOUT */
      --c-header: var(--c-blue-base);
      --c-surface: var(--c-blue-base);
      --c-error: var(--c-red400);

      /* TEXTS */
      --c-text: var(--c-white);
      --c-text-secondary: var(--c-white);
      --a-title-align: center;

      /* BUTTONS */
      --c-primary: linear-gradient(180deg, #efff33 0%, #9df82a 100%);
      --c-primary-text: var(--c-black);
      --c-primary--hover: linear-gradient(180deg, #f5fe84 0%, #c5fb7f 100%);
      --c-primary--focus: linear-gradient(180deg, #f5fe84 0%, #c5fb7f 100%);
      --c-primary--active: linear-gradient(
        180deg,
        #efff3399 0%,
        #9df82a99 100%
      );
      --c-primary--disabled: var(--c-blue850);
      --c-primary-color--disabled: #082761;

      --c-secondary: var(--c-white);
      --c-secondary-text: var(--c-black);
      --c-secondary--hover: var(--c-blue75);
      --c-secondary--focus: var(--c-blue75);
      --c-secondary--active: var(--c-blue250);
      --c-secondary--disabled: var(--c-gray300);

      --c-promo: linear-gradient(to bottom, #fbff3e, var(--c-yellow450));
      --c-promo-text: var(--c-black);
      --c-promo--hover: linear-gradient(
        to bottom,
        var(--c-yellow450),
        var(--c-yellow500)
      );
      --c-promo--focus: linear-gradient(
        to bottom,
        var(--c-yellow450),
        var(--c-yellow500)
      );
      --c-promo--active: linear-gradient(
        to bottom,
        var(--c-yellow450),
        var(--c-yellow500)
      );
      --c-promo--disabled: var(--c-gray300);
      --c-promo-color--disabled: var(--c-gray400);

      /* INPUTS */
      --c-input-border: var(--c-blue-border);
      --c-input-border-focus: var(--c-blue75);
      --c-input-border-error: var(--c-red500);
      --c-input-text-color: var(--c-white);
      --c-input-placeholder-color: var(--c-blue250);
      --f-input-font-weight: 700;
      --h-input-height: 72px;

      --c-animated-input-color: var(--c-white);
      --c-animated-input-label-color: var(--c-blue250);
      --c-animated-input-border: var(--c-blue-border);
      --c-animated-input-border-focus: var(--c-white);
      --c-animated-input-autofill: var(--c-white);

      /* HEADER */
      --c-progress-bar: var(--c-blue-border);
      --c-progress-bar-line: linear-gradient(
        90deg,
        #4750fe -32.09%,
        #24d8ff 33.36%,
        var(--c-vibrant-green) 99.68%
      );

      /* SUBSCRIPTION */
      --c-faq-background: var(--c-blue850);
      --c-faq-icon: var(--c-white);
      --c-faq-icon-background: var(--c-white);
      --c-plan-image: linear-gradient(
          to top,
          var(--c-blue-base) 0%,
          transparent
        )
        50%;
    `
  }

  if (theme?.digitalV3) {
    return css`
      /* LAYOUT */
      --c-header: var(--c-white);
      --c-surface: var(--c-blue0);
      --c-error: var(--c-red400);

      /* TEXTS */
      --c-text: var(--c-black);
      --c-text-secondary: var(--c-gray700);
      --a-title-align: flex-start;

      /* BUTTONS */
      --c-primary: var(--c-blue400);
      --c-primary-text: var(--c-white);
      --c-primary--hover: var(--c-blue300);
      --c-primary--focus: var(--c-blue300);
      --c-primary--active: var(--c-blue200);
      --c-primary--disabled: var(--c-gray300);
      --c-primary-color--disabled: var(--c-gray400);

      --c-secondary: var(--c-black);
      --c-secondary-text: var(--c-white);
      --c-secondary--hover: var(--c-gray800);
      --c-secondary--focus: var(--c-gray800);
      --c-secondary--active: var(--c-gray600);
      --c-secondary--disabled: var(--c-gray300);
      --c-secondary-color--disabled: var(--c-gray400);

      --c-promo: var(--c-yellow400);
      --c-promo-text: var(--c-black);
      --c-promo--hover: var(--c-yellow500);
      --c-promo--focus: var(--c-yellow500);
      --c-promo--active: var(--c-yellow500);
      --c-promo--disabled: var(--c-gray300);
      --c-promo-color--disabled: var(--c-gray400);

      /* INPUTS */
      --c-input-border: var(--c-gray400);
      --c-input-border-focus: var(--c-gray900);
      --c-input-border-error: var(--c-red500);
      --c-input-text-color: var(--c-black);
      --c-input-placeholder-color: var(--c-gray600);
      --f-input-font-weight: 500;
      --h-input-height: 56px;

      --c-animated-input-color: var(--c-black);
      --c-animated-input-label-color: var(--c-gray600);
      --c-animated-input-border: var(--c-gray500);
      --c-animated-input-border-focus: var(--c-gray900);
      --c-animated-input-autofill: var(--c-black);

      /* HEADER */
      --c-progress-bar: var(--c-gray300);
      --c-progress-bar-line: var(--c-blue400);

      /* SUBSCRIPTION */
      --c-faq-background: var(--c-white);
      --c-faq-icon: var(--c-black);
      --c-faq-icon-background: var(--c-gray400);
      --c-plan-image: linear-gradient(
        to top,
        var(--c-blue0) 0%,
        transparent 50%
      );
    `
  }

  if (theme?.digitalV4) {
    return css`
      /* LAYOUT */
      --c-header: var(--c-blue-base);
      --c-surface: var(--c-blue850);
      --c-error: var(--c-red400);

      /* TEXTS */
      --c-text: var(--c-white);
      --c-text-secondary: var(--c-white);
      --a-title-align: flex-start;
      --c-text-disabled: var(--c-gray400);

      /* BUTTONS */
      --c-primary: var(--c-vibrant-green);
      --c-primary-text: var(--c-blue950);
      --c-primary--hover: var(--c-green200);
      --c-primary--focus: var(--c-green200);
      --c-primary--disabled: rgba(175, 255, 45, 0.3);
      --c-primary-color--disabled: var(--c-blue-base);

      --c-secondary: var(--c-white);
      --c-secondary-text: var(--c-black);
      --c-secondary--hover: var(--c-gray300);
      --c-secondary--focus: var(--c-gray300);
      --c-secondary--active: var(--c-gray400);
      --c-secondary--disabled: var(--c-gray300);
      --c-secondary-color--disabled: var(--c-gray400);

      --c-promo: var(--c-yellow500);
      --c-promo-text: var(--c-black);
      --c-promo--hover: var(--c-yellow400);
      --c-promo--focus: var(--c-yellow400);
      --c-promo--active: var(--c-yellow400);
      --c-promo--disabled: color-mix(
        in srgb,
        var(--c-yellow500) 30%,
        transparent
      );
      --c-promo-color--disabled: color-mix(
        in srgb,
        var(--c-blue950) 30%,
        transparent
      );

      /* INPUTS */
      --c-input-border: var(--c-faq-icon-background);
      --c-input-border-focus: var(--c-blue75);
      --c-input-border-error: var(--c-red500);
      --c-input-text-color: var(--c-white);
      --c-input-placeholder-color: var(--c-faq-icon-background);
      --f-input-font-weight: 500;
      --h-input-height: 56px;

      --c-animated-input-color: var(--c-white);
      --c-animated-input-label-color: var(--c-blue550);
      --c-animated-input-border: var(--c-blue550);
      --c-animated-input-border-focus: var(--c-white);
      --c-animated-input-autofill: var(--c-white);

      /* HEADER */
      --c-progress-bar: #041e42;
      --c-progress-bar-line: var(--c-vibrant-green);

      /* SUBSCRIPTION */
      --c-faq-background: var(--c-blue850);
      --c-faq-icon: var(--c-white);
      --c-faq-icon-background: var(--c-blue550);
      --c-plan-image: linear-gradient(
        to top,
        var(--c-blue-base) 0%,
        transparent 50%
      );
    `
  }

  return null
}
