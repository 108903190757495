import { RemoteConfig } from 'fitify-ui/src/hooks/useRemoteConfig'
import { toBoolean } from 'fitify-utils/src/properties'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

import { IRouteType } from '../types'
import { encodeQuery } from '../utils/query'

import { usePromo } from './usePromo'

interface IPagingResult {
  prev: IRouteType<string>
  current: IRouteType<string>
  next: IRouteType<string>
}

// Cache the routes and allowed state keys
const PAGES_CACHE: IRouteType<string>[] = []

/**
 * Initializes the navigation cache with allowed routes based on the configuration object.
 * @param {IRouteType<string>[]} routes - An array of route objects.
 * @param {RemoteConfig} config - The configuration object containing the flag values.
 * @returns {string[]} An array of route keys corresponds to state data keys.
 */
export const useInitNavigation = (
  routes: IRouteType<string>[],
  config: RemoteConfig
): IRouteType<string>[] => {
  const promoCode = usePromo()

  return useMemo(() => {
    // Filter the routes based on the config flag and return a new array of pages with encoded promo code.
    const filteredRoutes = routes.filter((route) => {
      return route.path && route.flag ? toBoolean(config[route.flag]) : true
    })

    const encodedRoutes = filteredRoutes.map((route) => ({
      ...route,
      path: encodeQuery(route.path, { promo: promoCode }),
    }))

    // Set the cache with the new array of pages.
    PAGES_CACHE.length = 0
    PAGES_CACHE.push(...encodedRoutes)

    return PAGES_CACHE.map((route) => route || undefined).filter(Boolean)
    // Set the routesLoaded flag to true to prevent the cache from being reset.
  }, [config, promoCode, routes])
}

/**
 * Returns the paging result for the given path, promo, and data.
 *
 * @param {Object} options - The options object.
 * @param {string} options.path - The path of the current page.
 * @param {string} [options.promo] - The promo code for the current page.
 * @param {Object} [options.data] - The data of a state { age: 25 } to use for condition checking.
 * @returns {IPagingResult} The paging result containing the previous, current, and next pages.
 */
export const getPaging = ({
  path,
  data,
}: {
  path: string
  data?: Record<string, any>
}): IPagingResult => {
  if (!PAGES_CACHE.length) return {} as IPagingResult

  const pages = PAGES_CACHE.filter((route) =>
    route.path && route.condition && data ? route.condition(data) : true
  )

  // Find the index of the current page
  const currentIndex = pages.findIndex((route) => route.path.includes(path))

  if (currentIndex === -1) {
    return {} as IPagingResult
  } else {
    // Get the current, previous, and next pages
    const prev = pages[Math.max(currentIndex - 1, 0)]
    const current = pages[currentIndex]
    const next = pages[Math.min(currentIndex + 1, pages.length - 1)]

    return {
      prev,
      current,
      next,
    }
  }
}

/**
 * Returns the progress of the current step as a percentage based on the total number of steps.
 *
 * @returns {number} The progress of the current step as a percentage.
 */
export const useStepProgress = (): number => {
  const router = useRouter()
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (!PAGES_CACHE.length) return

    // Filter the routes based on the config flag
    const path = router.pathname
    const stepRoutes = PAGES_CACHE.filter((route) => route.flag)
    const currentIndex = stepRoutes.findIndex((route) =>
      route.path.includes(path)
    )

    // If the current page is not found in the stepRoutes array, return 0.
    if (currentIndex === -1) return

    // Calculate the progress as a percentage
    setProgress(Math.floor((currentIndex / (stepRoutes.length - 1)) * 100))
  }, [router])

  return progress
}

export const usePreload = () => {
  const router = useRouter()

  useEffect(() => {
    if (!PAGES_CACHE.length) return
    const path = router.pathname

    // Find the index of the current page
    const currentIndex = PAGES_CACHE.findIndex((route) =>
      route.path.includes(path)
    )

    const next = PAGES_CACHE[Math.min(currentIndex + 1, PAGES_CACHE.length - 1)]

    // Preload the next page
    void router.prefetch(next.path)
  }, [router])
}
