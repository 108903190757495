import { type Theme, Global, css } from '@emotion/react'

import { colors, themeColors } from './colors'
import { coreElements } from './core'
import { themeVariables } from './variables'

export const GlobalStyles = ({ theme }: { theme: Theme }) => {
  return (
    <Global
      styles={css`
        :root {
          /* VARIABLES */
          ${colors()}
          ${themeColors(theme)}
          ${themeVariables(theme)}

          /* CORE */
          ${coreElements(theme)}
        }
      `}
    />
  )
}
