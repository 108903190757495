import { ReactNode } from 'react'

export enum CloseButtonTypes {
  BLACK = 'black',
  WHITE = 'white',
}

export enum ModalSizeTypes {
  DEFAULT = 'default',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  FULL = 'full',
}

export interface ModalConfigProps {
  modalSize?: ModalSizeTypes
  borderRadius?: number
  showHeader?: boolean
  showOverlay?: boolean
  showLogo?: boolean
  showCloseButton?: boolean
  closeVariant?: CloseButtonTypes
  animationDuration?: number
  dataTestId?: string
  overlayIndex?: number
}

export interface ModalElementOptions {
  title?: ReactNode
  logo?: ReactNode
  closeButton?: ReactNode
}

export interface ModalTypes {
  children: ReactNode
  show: boolean
  handleClose: () => void
  autofocus?: boolean
  options?: ModalElementOptions
  config?: ModalConfigProps
}

export interface ModalPortalTypes {
  children: ReactNode
  wrapperId: string
  dataTestId?: string
}

export interface ModalContainerProps {
  size?: ModalSizeTypes
  borderRadius?: number
}

export interface ModalCloseProps {
  variant: CloseButtonTypes
}
