import { useTheme } from '@emotion/react'
import dynamic from 'next/dynamic'
import { CSSProperties } from 'react'

const ThresholdWrapper = dynamic(() => import('./ThresholdWrapper'))
const ThresholdWrapperV2 = dynamic(() => import('./ThresholdWrapperV2'))

export type ThresholdProps = {
  hasBackground: boolean
  children: JSX.Element
  style?: CSSProperties
}

export const Threshold = ({
  hasBackground,
  children,
  style,
}: ThresholdProps) => {
  const theme = useTheme()

  return theme.digitalV2 || theme.digitalV4 ? (
    <ThresholdWrapper hasBackground={hasBackground}>
      {children}
    </ThresholdWrapper>
  ) : (
    <ThresholdWrapperV2 hasBackground={hasBackground} style={style}>
      {children}
    </ThresholdWrapperV2>
  )
}
