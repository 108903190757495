type StorageType = 'local' | 'session'

const wrapStorage = <TReturnValue>(
  storageType: StorageType,
  callback: (storage: Storage) => TReturnValue
) => {
  if (typeof window !== 'undefined') {
    const storage =
      storageType === 'local' ? window.localStorage : window.sessionStorage
    return callback(storage)
  }
  return undefined
}

export function setItem(
  storageType: StorageType,
  key: string,
  value: string
): void {
  return wrapStorage<void>(storageType, (storage) => {
    try {
      storage.setItem(key, value)
    } catch (e) {
      console.error('Error setting item in storage:', e)
    }
  })
}

export function getItem(storageType: StorageType, key: string): string | null {
  return (
    wrapStorage<string | null>(storageType, (storage) => {
      try {
        return storage.getItem(key)
      } catch (e) {
        console.error('Error getting item from storage:', e)
        return null
      }
    }) ?? null
  )
}

export function clearItem(storageType: StorageType, key: string): void {
  return wrapStorage<void>(storageType, (storage) => {
    try {
      storage.removeItem(key)
    } catch (e) {
      console.error('Error clearing item from storage:', e)
    }
  })
}

export function clearAll(storageType: StorageType): void {
  return wrapStorage<void>(storageType, (storage) => {
    try {
      storage.clear()
    } catch (e) {
      console.error('Error clearing storage:', e)
    }
  })
}
