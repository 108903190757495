import { Coach } from 'fitify-types/src/types/coach'
import { WithId } from 'fitify-types/src/types/common'
import { RemoteContext } from 'fitify-ui/src/hooks/useRemoteConfig'
import {
  Chip,
  ChipStyledProps,
  Stack,
  Text,
} from 'fitify-ui-onboarding/src/components'
import { usePromo } from 'fitify-ui-onboarding/src/hooks/usePromo'
import { SubscriptionPlan } from 'fitify-ui-onboarding/src/types'
import Image from 'next/image'
import { useContext } from 'react'

import {
  StyledChipContainer,
  StyledHeaderProps,
  StyledPaywallHeading,
  StyledPaywallSubheading,
  StyledSubscriptionHeader,
  StyledSubscriptionHeaderCoachImage,
} from './Plan.Styled'
import { usePlanTitle } from './utils'

export const PlanHeader = ({
  title,
  plans,
  coach,
  badge,
  textAlign,
}: {
  title?: string
  plans?: SubscriptionPlan[]
  coach?: {
    isEnabled: boolean
    data?: WithId<Coach>
  }
  badge?: {
    isEnabled: boolean
    variant: ChipStyledProps['variant']
    label?: string
  }
} & StyledHeaderProps) => {
  const promoCode = usePromo()
  const remoteConfig = useContext(RemoteContext)
  const promoSubtitle = remoteConfig[`promo_${promoCode}_subtitle`]

  const isCoachEnabled = coach?.isEnabled
  const selectedCoach = coach?.data

  const content = usePlanTitle(plans)
  const planTitle = title || content.title

  switch (true) {
    case isCoachEnabled:
      return (
        <StyledSubscriptionHeader textAlign={'left'} hasCoach>
          <Stack orientation="horizontal">
            <StyledSubscriptionHeaderCoachImage>
              {selectedCoach && selectedCoach.profile_pic ? (
                <Image
                  alt={'Coach profile image'}
                  width={96}
                  height={96}
                  style={{ borderRadius: '96px' }}
                  src={selectedCoach.profile_pic}
                />
              ) : (
                <Image
                  alt={'No selected coach'}
                  width={96}
                  height={96}
                  src={'/images/no-selected-coach@2x.webp'}
                />
              )}
            </StyledSubscriptionHeaderCoachImage>

            <Stack xs="0.25rem">
              {badge && badge.isEnabled ? (
                <StyledChipContainer align={'left'}>
                  <Chip variant={badge.variant}>
                    {badge.label || content.badge}
                  </Chip>
                </StyledChipContainer>
              ) : null}

              <Text
                as="h1"
                size="xl"
                dangerouslySetInnerHTML={{
                  __html: planTitle,
                }}
                weight="500"
              />
            </Stack>

            {promoSubtitle ? <Text>{promoSubtitle}</Text> : null}
          </Stack>
        </StyledSubscriptionHeader>
      )
    default:
      return (
        <StyledSubscriptionHeader
          xs="0.75rem"
          textAlign={textAlign}
          style={{ marginBottom: '24px' }}
        >
          {badge && badge.isEnabled ? (
            <StyledChipContainer>
              <Chip variant={badge.variant}>
                {badge.label || content.badge}
              </Chip>
            </StyledChipContainer>
          ) : null}

          <StyledPaywallHeading
            as="h1"
            size="xl"
            dangerouslySetInnerHTML={{
              __html: planTitle,
            }}
            weight="700"
          />

          {content.subtitle ? (
            <StyledPaywallSubheading>
              {content.subtitle}
            </StyledPaywallSubheading>
          ) : null}
        </StyledSubscriptionHeader>
      )
  }
}

export default PlanHeader
