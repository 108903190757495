import { type User } from 'firebase/auth'
import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  useEffect,
  useState,
} from 'react'

import {
  StyledLogoutLink,
  StyledUserAvatar,
  StyledUserContainer,
  StyledUserData,
  StyledUserEmail,
  StyledUserName,
} from './Header.Styled'

type HeaderUserProps = {
  data: {
    user: User
    logoutText: string
  }
  onHandleLogout: () => void
}

function HeaderUserAvatar({
  src,
  ...props
}: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  const [isHidden, setIsHide] = useState(true)

  useEffect(() => {
    if (src) {
      setIsHide(false)
    }
  }, [src])

  if (isHidden) return null
  return (
    <StyledUserAvatar src={src} {...props} onError={() => setIsHide(true)} />
  )
}

const HeaderUser = ({ data, onHandleLogout }: HeaderUserProps) => {
  const { user, logoutText } = data

  return (
    <StyledUserContainer>
      {user.photoURL && (
        <HeaderUserAvatar
          src={user.photoURL ?? undefined}
          alt={user.displayName ?? undefined}
        />
      )}

      <StyledUserData>
        {user.displayName ? (
          <StyledUserName title={user?.email ?? ''}>
            {user.displayName}
          </StyledUserName>
        ) : (
          <StyledUserEmail title={user?.email ?? ''}>
            {user?.email}
          </StyledUserEmail>
        )}

        <StyledLogoutLink onClick={onHandleLogout}>
          {logoutText}
        </StyledLogoutLink>
      </StyledUserData>
    </StyledUserContainer>
  )
}

export default HeaderUser
