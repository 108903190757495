type IValue = string | number | boolean | undefined | null

function fromEntries(entries: [string, IValue][]) {
  return entries.reduce<{ [k: string]: IValue }>((memo, [key, value]) => {
    memo[key] = value
    return memo
  }, {})
}

export const encodeQuery = (
  url: string,
  args: { [key: string]: IValue } = {}
): string => {
  const [hostUrl, search] = url.split('?')
  const oldParams = fromEntries([
    ...new URLSearchParams('?' + (search ?? '')).entries(),
  ])

  const params = Object.entries(Object.assign(oldParams, args))
    .filter(
      (x: [string, IValue]): x is [string, NonNullable<IValue>] =>
        x[1] !== null && x[1] !== ''
    )
    .map((pair) => pair.map(encodeURIComponent).join('='))

  if (params.length > 0) {
    return `${hostUrl}?${params.join('&')}`
  }
  return hostUrl
}
