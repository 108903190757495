import { useEffect, useState } from 'react'

const useDelayUnmount = (isMounted: boolean, delayTime: number): boolean => {
  // If delayTime is 0, we don't want to delay the unmount
  // If setting up animation, we want to delay the unmount
  // Unmount can happen faster than animation so to prevent flickering we want end unmount sooner than animation
  delayTime = delayTime ? delayTime - delayTime * 0.1 : 0

  const [shouldRender, setShouldRender] = useState<boolean>(false)

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>

    if (isMounted && !shouldRender) {
      setShouldRender(true)
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime)
    }

    return () => clearTimeout(timeoutId)
  }, [isMounted, delayTime, shouldRender])
  return shouldRender
}

export default useDelayUnmount
