import { UnitSystem } from 'fitify-types/src/types/common'

export const MASS_KG_LBS_COEFFICIENT = 2.2046
export const DISTANCE_M_TO_MILE_COEFICIENT = 0.000621371192
export const INCHES_TO_CM_COEFFICIENT = 2.54

export const kilogramsToPounds = (weight: number): number => {
  return weight * MASS_KG_LBS_COEFFICIENT
}

export const poundsToKilograms = (
  weight: number,
  precision?: number
): number => {
  const rawKgs = weight / MASS_KG_LBS_COEFFICIENT
  if (precision !== undefined) {
    return Math.round(rawKgs * 10 ** precision) / 10 ** precision
  }
  return rawKgs
}

export const centimetersToInches = (
  height: number,
  precision?: number
): number => {
  const rawInches = height / INCHES_TO_CM_COEFFICIENT
  if (precision !== undefined) {
    return Math.round(rawInches * 10 ** precision) / 10 ** precision
  }

  return Math.round(rawInches)
}

export const metersToMiles = (distance: number): number => {
  return distance * DISTANCE_M_TO_MILE_COEFICIENT
}

export const metersToKm = (distance: number): number => {
  return distance / 1000
}

export const roundWeightToNearestHalf = (weight?: number) => {
  if (!weight) {
    return 0
  }
  const roundedWeight = Math.round(weight * 2) / 2
  return Number.isInteger(roundedWeight)
    ? roundedWeight
    : parseFloat(roundedWeight.toFixed(1))
}

export const roundDistanceToNearestTenth = (distance?: number) => {
  if (!distance) {
    return 0
  }

  const roundedDistance = Math.round(distance * 10) / 10
  return Number.isInteger(roundedDistance)
    ? roundedDistance
    : parseFloat(roundedDistance.toFixed(1))
}

export const inchesToCentimeters = (
  value: number,
  precision?: number
): number => {
  if (precision) {
    return (
      (Math.round(value * INCHES_TO_CM_COEFFICIENT) * 10 ** precision) /
      10 ** precision
    )
  }
  return value * INCHES_TO_CM_COEFFICIENT
}

export const convertWeightUnit = (
  weight: number,
  formerUnit: UnitSystem,
  resultUnit: UnitSystem,
  decimalPlaces = 0
) => {
  const resultWeight =
    resultUnit === UnitSystem.Metric
      ? formerUnit === UnitSystem.Imperial
        ? poundsToKilograms(weight)
        : weight
      : formerUnit === UnitSystem.Metric
        ? kilogramsToPounds(weight)
        : weight
  return Math.round(resultWeight * 10 ** decimalPlaces) / 10 ** decimalPlaces
}

export const convertHeightUnit = (
  height: number,
  formerUnit: UnitSystem,
  resultUnit: UnitSystem,
  decimalPlaces = 0
) => {
  const resultHeight =
    resultUnit === UnitSystem.Metric
      ? formerUnit === UnitSystem.Imperial
        ? inchesToCentimeters(height)
        : height
      : formerUnit === UnitSystem.Metric
        ? centimetersToInches(height)
        : height
  return Math.round(resultHeight * 10 ** decimalPlaces) / 10 ** decimalPlaces
}
