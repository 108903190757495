import React, { ChangeEvent, KeyboardEvent, forwardRef } from 'react'

import { StyledFormInput } from '../../Form/Form'

interface NumberInputProps {
  autoFocus?: boolean
  name: string
  placeholder: string
  value: string | number | undefined
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (
    event: KeyboardEvent<HTMLInputElement>,
    isNumeric: boolean
  ) => void
  onWheel?: (event: React.WheelEvent<HTMLInputElement>) => void
}

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    { autoFocus, name, placeholder, onChange, onKeyDown, onWheel, value },
    ref
  ) => {
    return (
      <StyledFormInput
        autoFocus={autoFocus}
        type="number"
        name={name}
        placeholder={placeholder}
        pattern="\d*"
        onChange={onChange}
        onKeyDown={(e) => onKeyDown(e, true)}
        onWheel={onWheel ? onWheel : (e) => e.currentTarget.blur()}
        value={value}
        ref={ref}
      />
    )
  }
)

export default NumberInput
