export function isEmptyObject(obj: Record<string, unknown>) {
  return Object.keys(obj).length === 0
}

export function areAllValuesUndefined(obj: Record<string, any>): boolean {
  if (typeof obj !== 'object' || !obj) return false

  for (const key in obj) {
    if (obj[key] !== undefined) {
      return false
    }
  }
  return true
}

export function filterUndefinedValues(
  obj: Record<string, any>
): Record<string, any> {
  const filteredObject: Record<string, any> = {}

  for (const key in obj) {
    if (obj[key] !== undefined) {
      filteredObject[key] = obj[key]
    }
  }

  return filteredObject
}
