import { css } from '@emotion/react'

import { Stack } from './Stack'

export function Steps({
  className,
  from,
  to,
}: {
  className?: string
  from?: number
  to?: number
}) {
  return (
    <Stack xs={4} className={className} orientation="horizontal">
      {Array(to ?? 0)
        .fill(0)
        .map((_, index) => (
          <div
            key={index}
            css={css`
              width: 23px;
              height: 2px;
              border-radius: 9999px;
              background: var(--c-white);
              opacity: ${index < (from ?? 0) ? 1 : 0.3};
            `}
          />
        ))}
    </Stack>
  )
}
