import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const StyledInput = styled.input``
export const StyledInputLabel = styled.label``

export const StyledInputContainer = styled.div<{
  invalid?: boolean
  hasValue: boolean
}>`
  width: 100%;
  max-width: 140px;
  position: relative;
  display: flex;
  flex-direction: column;

  ${StyledInputLabel} {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    font-family: 'Graphik LCG Web';
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    color: #757575;
    font-size: 16px;
    line-height: 1;
    left: 16px;

    ${(props) =>
      props.invalid &&
      css`
        color: #ec530c;
      `}

    ${(props) =>
      props.hasValue &&
      css`
        transform: translate(0, 12px);
        color: #757575;
        font-size: 12px;
      `}
  }

  ${StyledInput} {
    height: 3.75rem;
    border-radius: 0.25rem;
    border: none;
    padding: 1.5rem 1rem 0.25rem 1rem;
    font-size: 1rem;
    line-height: 1;
    outline: none;
    box-shadow: 0 0 0 1px #9e9e9e;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    font-family: 'Graphik LCG Web';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 120%;

    letter-spacing: -0.01rem;

    ${(props) =>
      props.invalid &&
      css`
        box-shadow: 0 0 0 1px #ec530c;
      `}
    &:focus {
      box-shadow: 0 0 0 1px #212121;
    }
  }

  &:focus-within ${StyledInputLabel} {
    transform: translate(0, 12px);
    color: #757575;
    font-size: 12px;
  }
`
