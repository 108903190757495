import { UnitSystem } from 'fitify-types/src/types/common'
import { validateHeight } from 'fitify-utils/src/validations/user'
import { ChangeEvent, useEffect, useState } from 'react'

import {
  CM_PER_INCH,
  cmToFeetAndInches,
  feetAndInchesToCm,
} from '../utils/unit'

export const useHeight = (unit: UnitSystem, initialHeight: number) => {
  const initialCmHeight =
    unit === 'metric' ? initialHeight : initialHeight * CM_PER_INCH
  const initialImperial = cmToFeetAndInches(initialCmHeight)

  const [heightCm, setHeightCm] = useState<number>(initialCmHeight)
  const [heightFeet, setHeightFeet] = useState<number>(initialImperial.feet)
  const [heightInches, setHeightInches] = useState<number>(
    initialImperial.inches
  )
  const currentImperial = cmToFeetAndInches(heightCm)
  const currentHeight =
    unit === 'metric' ? heightCm : currentImperial.inchesTotal

  const [isHeightEmpty, setIsHeightEmpty] = useState<boolean>(!!currentHeight)
  const [isHeightValid, setIsHeightValid] = useState<boolean>(true)

  const updateHeight = (feet: number, inches: number, cm: number) => {
    setHeightFeet(Number.isNaN(feet) ? NaN : feet)
    setHeightInches(Number.isNaN(inches) ? NaN : inches)
    setHeightCm(Number.isNaN(cm) ? NaN : cm)
  }

  const handleCmChange = (e: ChangeEvent<HTMLInputElement>) => {
    const cm = parseInt(e.target.value)
    const { feet, inches } = cmToFeetAndInches(cm)
    updateHeight(feet, inches, cm)
  }

  const handleFeetChange = (e: ChangeEvent<HTMLInputElement>) => {
    const feet = parseInt(e.target.value)
    const cm = feetAndInchesToCm(feet, heightInches)
    updateHeight(feet, heightInches, cm)
  }

  const handleInchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inches = Math.min(11, parseInt(e.target.value))
    const cm = feetAndInchesToCm(heightFeet, inches)
    updateHeight(heightFeet, inches, cm)
  }

  useEffect(() => {
    const isEmpty = Number.isNaN(heightCm)

    setIsHeightEmpty(isEmpty)

    if (!isEmpty) {
      if (unit === UnitSystem.Metric) {
        const isInRange = validateHeight(heightCm, UnitSystem.Metric)

        setIsHeightValid(isInRange)
      } else if (unit === UnitSystem.Imperial) {
        const isInRange = validateHeight(
          currentImperial.inchesTotal,
          UnitSystem.Imperial
        )

        setIsHeightValid(isInRange)
      }
    }
  }, [heightCm, currentImperial.inchesTotal, unit])

  return {
    currentHeight: Math.round(currentHeight),
    heightCm: heightCm,
    heightFeet: heightFeet,
    heightInches: heightInches,
    handleCmChange,
    handleFeetChange,
    handleInchChange,
    isHeightValid,
    isHeightEmpty,
  }
}
