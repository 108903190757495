import { User } from 'fitify-types/src/types/user'
import { IRouteType } from 'fitify-ui-onboarding/src/types'

import { IDataState } from './state'

// !TODO = chore rename FLAG to remoteFlag
// !TODO = chore rename KEY to stateKey

const ONBOARDING_PAGES_ROUTES = {
  home: {
    path: '/',
    event: 'hc_onboarding_welcome',
  },
  gender: {
    path: '/onboarding/gender',
    flag: 'hc_onboarding_gender_enabled',
    key: 'gender',
    event: 'hc_onboarding_gender',
    required: true,
  },
  name: {
    path: '/onboarding/name',
    flag: 'hc_onboarding_name_enabled',
    key: 'displayName',
    event: 'hc_onboarding_name',
  },
  goal: {
    path: '/onboarding/goal',
    flag: 'hc_onboarding_goals_enabled',
    key: 'hc_goals',
    event: 'hc_onboarding_goals',
  },
  previousExperience: {
    path: '/onboarding/previous-experience',
    flag: 'hc_onboarding_workout_experience_enabled',
    key: 'previous_experience',
    event: 'hc_onboarding_workout_experience',
  },
  age: {
    path: '/onboarding/age',
    flag: 'hc_onboarding_age_enabled',
    key: 'age',
    event: 'hc_onboarding_age',
    required: true,
  },
  numberOfClientsPitch: {
    path: '/onboarding/number-of-clients',
    flag: 'hc_onboarding_pitch_numberofclients_enabled',
    event: 'hc_onboarding_pitch_numberofclients',
    condition: (data: IDataState) => {
      return (
        data.gender &&
        data.age &&
        ['m', 'f'].includes(data.gender) &&
        data.age < 70
      )
    },
  },
  height: {
    path: '/onboarding/height',
    flag: 'hc_onboarding_height_enabled',
    key: 'height',
    event: 'hc_onboarding_height',
    required: true,
  },
  weight: {
    path: '/onboarding/weight',
    flag: 'hc_onboarding_weight_enabled',
    key: 'weight',
    event: 'hc_onboarding_weight',
    required: true,
  },
  goalWeight: {
    path: '/onboarding/goal-weight',
    flag: 'hc_onboarding_goal_weight_enabled',
    key: 'goal_weight',
    event: 'hc_onboarding_goal_weight',
    condition: (data: IDataState) => {
      return (
        data.hc_goals && data.hc_goals.includes(User.Profile.HcGoals.LoseWeight)
      )
    },
  },
  goalWeightPitch: {
    path: '/onboarding/goal-weight-progress',
    flag: 'hc_onboarding_goal_weight_pitch_enabled',
    event: 'hc_onboarding_goal_weight_pitch',
    condition: (data: IDataState) => {
      return (
        data.hc_goals &&
        data.hc_goals.includes(User.Profile.HcGoals.LoseWeight) &&
        data.weight &&
        data.goal_weight &&
        data.weight > data.goal_weight
      )
    },
  },
  lastIdealShape: {
    path: '/onboarding/last-ideal-shape',
    flag: 'hc_onboarding_last_ideal_shape_enabled',
    key: 'hc_last_ideal_shape',
    event: 'hc_onboarding_last_ideal_shape',
  },
  typicalDay: {
    path: '/onboarding/typical-day',
    flag: 'hc_onboarding_typical_day_enabled',
    key: 'hc_typical_day',
    event: 'hc_onboarding_typical_day',
  },
  lifestyleHabits: {
    path: '/onboarding/habits',
    flag: 'hc_onboarding_lifestyle_habits_enabled',
    key: 'hc_lifestyle_habits',
    event: 'hc_onboarding_lifestyle_habits',
  },
  commitment: {
    path: '/onboarding/commitment',
    flag: 'hc_onboarding_commitment_enabled',
    key: 'hc_commitment',
    event: 'hc_onboarding_commitment',
  },
  trainersPitch: {
    path: '/onboarding/our-trainers',
    flag: 'hc_onboarding_pitch_ourtrainers_enabled',
    event: 'hc_onboarding_pitch_ourtrainers',
  },
  workoutsPerWeek: {
    path: '/onboarding/workout-frequency',
    flag: 'hc_onboarding_target_workout_frequency_enabled',
    key: 'hc_workouts_per_week',
    event: 'hc_onboarding_workouts_per_week',
  },
  workoutEnvironment: {
    path: '/onboarding/workout-environment',
    flag: 'hc_onboarding_where_enabled',
    key: 'hc_workout_environment',
    event: 'hc_onboarding_where',
  },
  whyFailed: {
    path: '/onboarding/why-failed',
    flag: 'hc_onboarding_why_failed_enabled',
    key: 'hc_why_failed',
    event: 'hc_onboarding_why_failed',
  },
  motivation: {
    path: '/onboarding/motivation',
    flag: 'hc_onboarding_motivation_goal_enabled',
    key: 'hc_motivation',
    event: 'hc_onboarding_motivation_goal',
  },
  moreQuestionsPitch: {
    path: '/onboarding/few-more-questions',
    flag: 'hc_onboarding_pitch_fewmorequestions_enabled',
    event: 'hc_onboarding_pitch_fewmorequestions',
  },
  coachSkillsFocus: {
    path: '/onboarding/coach-skills-focus',
    flag: 'hc_onboarding_coach_skills_focus_enabled',
    key: 'hc_coach_skills_focus',
    event: 'hc_onboarding_coach_skills_focus',
  },
  coachGender: {
    path: '/onboarding/coach-gender',
    flag: 'hc_onboarding_coach_prefered_gender_enabled',
    key: 'hc_coach_gender',
    event: 'hc_onboarding_coach_gender',
  },
  coachingStyle: {
    path: '/onboarding/coach-style',
    flag: 'hc_onboarding_coaching_style_enabled',
    key: 'hc_coaching_style',
    event: 'hc_onboarding_coaching_style',
  },
  deviceOs: {
    path: '/onboarding/device-os',
    flag: 'hc_onboarding_device_os_enabled',
    key: 'device_os',
    event: 'hc_onboarding_device_os',
  },
  deviceAppleWatch: {
    path: '/onboarding/device-apple-watch',
    flag: 'hc_onboarding_device_watch_enabled',
    key: 'device_apple_watch',
    event: 'hc_onboarding_device_watch',
    condition: (data: IDataState) => {
      return data.device_os && data.device_os !== User.Profile.DeviceOS.Android
    },
  },
  deviceAppleWatchSharing: {
    path: '/onboarding/device-apple-watch-sharing',
    flag: 'hc_onboarding_pitch_applewatch_yes_enabled',
    event: 'hc_onboarding_pitch_applewatch',
    condition: (data: IDataState) => {
      return data.device_os && data.device_os !== User.Profile.DeviceOS.Android
    },
  },
  loadingCoaches: {
    path: '/onboarding/loading-coaches',
    event: 'hc_onboarding_generator_progress',
  },
  selectCoach: {
    path: '/onboarding/select-coach',
    event: 'hc_onboarding_coach_list',
  },
  subscription: {
    path: '/onboarding/subscription',
    event: 'hc_onboarding_subscription',
  },
  success: {
    path: '/onboarding/success',
    event: 'hc_onboarding_success',
  },
} as const

const APP_ROUTES = {
  gift: {
    path: '/gift',
    event: 'hc_onboarding_gift',
  },
  giftSuccess: {
    path: '/gift/success',
    event: 'hc_onboarding_gift_success',
  },
  login: {
    path: '/auth/login',
    event: 'hc_onboarding_signin',
  },
  loginEmail: {
    path: '/auth/login/email',
    event: 'hc_onboarding_signin_email',
  },
  passwordReset: {
    path: '/auth/login/password-reset',
    event: 'onboarding_login_password_reset',
  },
  signup: {
    path: '/auth/signup',
    event: 'hc_onboarding_signup',
  },
  signupEmail: {
    path: '/auth/signup/email',
    event: 'hc_onboarding_signup_email',
  },
  redeem: {
    path: '/redeem',
    event: 'hc_onboarding_redeem',
  },
  redeemSuccess: {
    path: '/redeem/success',
    event: 'hc_onboarding_redeem_success',
  },
}

export type NavigationKeys = keyof typeof ONBOARDING_PAGES_ROUTES
export const ONBOARDING_PAGES = ONBOARDING_PAGES_ROUTES as {
  [key in NavigationKeys]: IRouteType<keyof IDataState>
}
export const ONBOARDING_PAGES_MAP = Object.values(
  ONBOARDING_PAGES
) as IRouteType<keyof IDataState>[]

export type AppKeys = keyof typeof APP_ROUTES
export const APP_PAGES = APP_ROUTES as {
  [key in AppKeys]: {
    path: string
    event: string
  }
}

export const APP_PAGES_MAP = Object.values(APP_PAGES) as IRouteType<
  keyof IDataState
>[]

export const ALL_PAGES_MAP = Object.values({
  ...ONBOARDING_PAGES_ROUTES,
  ...APP_ROUTES,
}) as IRouteType<keyof IDataState>[]
